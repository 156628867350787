.section-gallery {
  background-color: #d9d9d9;
  padding: 60px 0;
}

.section-gallery .u-title {
  font-family: Roboto,sans-serif;
  font-size: 2.75rem;
  font-weight: bold;
  color: #111111;
  text-align: center;
}

.section-gallery .App {
  height: calc(1140px * 0.3);
  margin: 40px 0;
  overflow: hidden;
}

.section-gallery .react-3d-carousel .slider-container .slider-left, 
.section-gallery .react-3d-carousel .slider-container .slider-right {
  height: calc(1140px * 0.3);
  margin: 0;
}

.section-gallery .react-3d-carousel .slider-container .slider-left div, 
.section-gallery .react-3d-carousel .slider-container .slider-right div {
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  padding: 0;
}
.section-gallery .react-3d-carousel .slider-container .slider-right div {
  border-left: 20px solid #fdc84b;
  border-right: 0;
}
.section-gallery .react-3d-carousel .slider-container .slider-left div {
  margin-left: 10px; 
  border-left: 0;
  border-right: 20px solid #fdc84b;
}


@media (max-width: 1199px) {
  .section-gallery .App {height: calc(940px * 0.3);}
  .section-gallery .react-3d-carousel .slider-container .slider-left, 
  .section-gallery .react-3d-carousel .slider-container .slider-right {height: calc(940px * 0.3);}
}

@media (max-width: 991px) {
  .section-gallery .App {height: calc(720px * 0.3);}
  .section-gallery .react-3d-carousel .slider-container .slider-left, 
  .section-gallery .react-3d-carousel .slider-container .slider-right {height: calc(720px * 0.3);}
}

@media (max-width: 767px) {
  .section-gallery .App {height: calc(540px * 0.3);}
  .section-gallery .react-3d-carousel .slider-container .slider-left, 
  .section-gallery .react-3d-carousel .slider-container .slider-right {height: calc(540px * 0.3);}
}

@media (max-width: 575px) {
  .section-gallery .App {height: calc(340px * 0.3);}
  .section-gallery .react-3d-carousel .slider-container .slider-left, 
  .section-gallery .react-3d-carousel .slider-container .slider-right {height: calc(340px * 0.3);}
}