.section-footer {
  background-color: #333333;
  color: #ffffff;
  padding: 60px 0;
}

.section-footer .u-cardbox {
  justify-content: flex-end;
}

.section-footer .u-cardbox .u-card {
  margin-left: 30px;
}

.section-footer .u-cardbox .u-card .u-card-wrap h4.u-title {
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.2;
  text-align: left;
}

.section-footer .u-cardbox .u-card .u-card-wrap p {
  font-size: 0.9rem;
  text-align: left;
}

.section-footer .u-line {
  border-top: solid 1px #fff;
  margin: 30px auto 0 0;
  opacity: 0.5;
}

@media (max-width: 767px) {
  .section-footer .u-cardbox .u-card {width: 100%; margin: 0;}
}