body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.u-sheet {
  position: relative;
  width: 1140px;
  margin: 0 auto;
}

.u-title {
  font-size: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 0px;
  margin: 0;
}

.u-cardbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.u-card {
  position: relative;
  display: block;
}

@media (max-width: 1199px) {
  .u-sheet {width: 940px;}
}

@media (max-width: 991px) {
  .u-sheet {width: 720px;}
}

@media (max-width: 767px) {
  .u-sheet {width: 540px;}
}

@media (max-width: 575px) {
  .u-sheet {width: 340px;}
}