.u-card-wrap {
  padding: 30px 20px;
}

.u-card-wrap span {
  width: 64px;
  margin: 0 auto;
  display: block;
}

.u-card-wrap h4.u-title {
  font-family: Roboto,sans-serif;
}

.u-card-wrap p {
  font-family: 'Open Sans',sans-serif;
}
.u-card-wrap p a {
  font-family: 'Open Sans',sans-serif;
  font-size: 0.9rem;
  color: #FFF;
  text-decoration: none;
}


.section-features .u-cardbox .u-card .u-card-wrap

@media (max-width: 767px) {
  .u-card-wrap {padding: 30px;}
}