.section-features {
  background-color: #d9d9d9;
  padding: 60px 0;
}

.section-features .u-title {
  font-family: Roboto,sans-serif;
  font-size: 2.75rem;
  font-weight: bold;
  color: #111111;
  text-align: center;
}

.section-features .u-cardbox {
  min-height: 312px;
  margin: 40px 0 0;
}

.section-features .u-cardbox .u-card {
  background-color: #FFF;
  max-width: 100%;
  width: calc(25% - 18px);
  margin-top: 20px;
}

.section-features .u-cardbox .u-card .u-card-wrap h4.u-title {
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  margin: 20px 0 0;
  padding: 0;
}

.section-features .u-cardbox .u-card .u-card-wrap p {
  line-height: 2.0;
  text-align: center;
  margin: 20px 0 0;
}


@media (max-width: 991px) {
  .section-features .u-cardbox .u-card {width: calc(50% - 18px);}
}

@media (max-width: 767px) {
  .section-features .u-cardbox .u-card {width: 100%;}
}