.section-banner {
  background-image: url("../../assets/images/banner.jpeg");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: cover;
  text-align: right;
  vertical-align: middle;
  display: block;
}

.section-banner .sheet {
  min-height: 857px;
}
.section-banner .sheet:before,
.section-banner .sheet:after {
  content: '';
  display: table;
}
.section-banner .sheet:after {
  clear: both;
}

.section-banner .sheet h1 {
  font-family: Roboto,sans-serif !important;
  font-size: 4.5rem;
  font-weight: 500;
  word-wrap: break-word;
  line-height: 1.1;
  pointer-events: initial;
  margin: 20px 0 0 494px;
}

.section-banner .sheet h3.u-title {
  font-family: 'PT Sans',sans-serif;
  font-weight: bold;
  color: #111111;
  word-wrap: break-word;
  line-height: 1.2;
  pointer-events: auto;
  margin: 437px 0 0 558px;
}

.section-banner .sheet p {
  font-family: 'Open Sans',sans-serif;
  font-size: 1.125rem;
  word-wrap: break-word;
  text-align: right;
  line-height: 2.0;
  margin: 20px 0 60px 660px;
  padding: 0;
}

@media (max-width: 1199px) {
  .section-banner .sheet {min-height: 707px;}
  .section-banner .sheet h3.u-title {margin-left: 358px;}
  .section-banner .sheet h1 {margin-left: 294px;}
  .section-banner .sheet p {margin-left: 450px;}
}

@media (max-width: 991px) {
  .section-banner .sheet {min-height: 660px;}
  .section-banner .sheet h3.u-title {margin-left: 138px;}
  .section-banner .sheet h1 {margin-left: 74px;}
  .section-banner .sheet p {margin-left: 280px;}
}

@media (max-width: 767px) {
  .section-banner .sheet {min-height: 576px;}
  .section-banner .sheet h3.u-title {margin-left: 347px;}
  .section-banner .sheet h1 {margin-left: auto;}
  .section-banner .sheet p {margin-left: auto;}
}

@media (max-width: 575px) {
  .section-banner {background-position: 22.76% 50%;}
  .section-banner .sheet {min-height: 729px;}
  .section-banner .sheet h3.u-title {margin-left: 100px;}
  .section-banner .sheet h1 {font-size: 3rem;}
}