.section-footer .logo {
  flex-grow: 1;
}
.section-footer .logo .logo-wrap {
  padding: 23px 20px 30px 10px;
}
.section-footer .logo a.u-logo {
  width: 159px;
  height: 67px;
  margin: 0;
  display: block;
}
.section-footer .logo a.u-logo img {
  width: 136px;
  height: auto;
}